<template>
  <div class="referral_page">
    <h1>Реферальна програма</h1>
    <div class="block_3">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <h3>
              Розповідайте про сервіс <br> <b-badge variant="success">
                та заробляйте гроші
              </b-badge>
            </h3>
            <div class="mb-2">
              <h5>Розповідайте про сервіс та заробляйте гроші</h5>
              <h5>Ласкаво просимо до панелі управління реферальної програми TeLeAds.com.ua . Діліться своїм унікальним посиланням, щоб запрошувати нових користувачів.</h5>
              <h5 v-if="prc.cr && prc.cr > 0">
                Заробляйте
                <b-badge variant="danger">
                  {{ prc.cr }}%
                </b-badge>
                з кожною успішною інтеграцією замовника.
              </h5>
              <h5 v-if="prc.grer && prc.grer > 0">
                +діє вступний бонус
                <b-badge variant="danger">
                  {{ prc.grer }}грн
                </b-badge>
                - запросіть друга і отримайте {{ prc.grer }}грн
              </h5>
            </div>
            <b-button
              variant="primary"
              size="lg"
              :to="`/login`"
              class="mb-2 mt-2"
            >
              Зареєструватись
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-img
              fluid
              :src="imgUrl"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'
import store from '@/store'
import router from '@/router'

export default {
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Реферальна програма | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Заробляйте 4% з кожної успішної інтеграції клієнта на біржі ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      item: null,
      prc: {
        afmn: 0,
        afmx: 0,
        awmn: 0,
        awmx: 0,
        cf: 0,
        cfm: 0,
        cr: 0,
        cs: 0,
        cw: 0,
        cwm: 0,
        gral: 0,
        grer: 0,
      },
      downImg: require('@/assets/images/pages/error.svg'),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    refDescription() {
      return this.$i18n.t('account.text.referral.description').replace(':comm', '2.9')
    },
  },
  mounted() {
    this.prc = JSON.parse(localStorage.getItem('prc')) ?? {}
    if (this.userData) {
      router.replace({ name: 'account:account-setting', query: { tab: 'referral' } })
    }
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
.referral_page {
  h1 {
    font-size: 3em;
    display: block;
    margin: 0.5em 0 1em 0;
    font-weight: bold;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 2em;
    }
  }

  .content-header {
    display: none;
  }

  .block_3 {
    padding: 0 0 3em;

    h3 {
      font-size: 2.4em;
      display: block;
      margin: 0 0 1em 0;
      font-weight: bold;
    }

    h5 {
      margin: 0 0 1em 0;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.6em;
      }
    }
  }
}
</style>
